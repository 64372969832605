<template>
    <div @mouseenter="submenuOnMouseEnter" @mouseleave="submenuOnMouseLeave">
        <b-nav-item-dropdown class="dropdownContainer" ref="dropdown" :href="href" :text="text" :dropright="dropright" :left="left">
            <slot></slot>
        </b-nav-item-dropdown>
    </div>
</template>

<script>
export default {
  name: 'NavItemDropdownHover',
  props: {
    href: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    dropright: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    console.log(this.href)
  },
  methods: {
    submenuOnMouseEnter () {
      this.$refs.dropdown.show()
    },
    submenuOnMouseLeave () {
      this.$refs.dropdown.hide()
    }
  }
}
</script>

<style scoped>
/* @media (min-width: 992px) {
  .dropdownContainer:hover >>> .dropdown-menu {
    display: block;
  }
  .dropdownContainer >>> .dropdown-menu {
    display: none;
  }
} */
</style>
