<template>
  <b-navbar class="navbar-container" toggleable="lg" type="light" variant="white">
    <a class="logo-header-container" :href="lienForum"><img id="logo-header" src = "@/assets/logo-header.png"></a>
    <b-navbar-toggle id="nav-toggle" target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto navContainer">
        <b-nav-item class="menuTopLinks" v-if="isLoggedIn" href="/">Répertoire</b-nav-item>
        <b-nav-item-dropdown class="menuTopLinks" text="À propos" :href="lienForum + '/sante/'" left>
          <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/sante/'">Qui sommes-nous?</b-dropdown-item>
          <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/tarifs/'">Tarifs</b-dropdown-item>
          <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/nos-partenaires/'">Nos partenaires</b-dropdown-item>
          <NavItemDropdownHover class="submenu" :href="lienForum + '/services/'" text="Nos services" dropright>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/medecine/'">Médecins</b-dropdown-item>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/infirmiers-et-infirmieres/'">Infirmiers et Infirmières</b-dropdown-item>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/nutritionnistes-dietetistes/'">Nutritionnistes / Diététistes</b-dropdown-item>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/kinesiologues/'">Kinésiologues</b-dropdown-item>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/psychologues/'">Psychologues</b-dropdown-item>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/sexologues/'">Sexologues</b-dropdown-item>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/psychoeducateurs-et-psychoeducatrices/'">Psychoéducateurs et psychoéducatrices</b-dropdown-item>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/travailleurs-sociaux-therapeutes-conjugaux-et-familiaux/'">Travailleurs sociaux, thérapeutes conjugaux et familiaux</b-dropdown-item>
          </NavItemDropdownHover>
          <!-- <b-nav-item-dropdown class="submenu" :href="lienForum + '/services/'" @mouseover="submenuOnMouseEnter" @mouseover.stop="submenuOnMouseLeave" text="Nos services" dropright>
          </b-nav-item-dropdown> -->
          <b-dropdown-item :href="lienForum + '/fiche-sante/'">Fiches santé</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown class="menuTopLinks" :href="lienForum + '/blog/'" text="Apprendre" left>
          <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/blog/'">Blogue</b-dropdown-item>
          <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/encyclopedie/'">Encyclopédie</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown class="menuTopLinks" text="Aides" :href="lienForum + '/forum/'" left>
          <NavItemDropdownHover text="Forum" class="submenu" :href="lienForum + '/forum/'" dropright>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/forum/'">Activité</b-dropdown-item>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/guide-dutilisation-du-forum/'">Guide d'utilisation du forum</b-dropdown-item>
            <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/regles/'">Règles d'utilisation du forum</b-dropdown-item>
          </NavItemDropdownHover>
          <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/aide-et-ressources/'">Aide et ressources</b-dropdown-item>
          <b-dropdown-item class="menuTopLinks-item" :href="lienForum + '/professionnel-de-la-sante/'">FAQ</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item class="menuTopLinks" :href="lienForum + '/contact/'">Contact</b-nav-item>
        <b-nav-item class="menuTopLinks" :href="lienForum + '/professionnel/'">Rejoindre l'équipe</b-nav-item>
        <b-button size="md" class="button-menu btnHoverBleu" v-if="isLoggedIn" @click="profil()"><img width="30" height="30" src="/calendar_inv.png"></b-button>
        <b-button size="md" class="button-menu btnHoverBleu" v-if="isLoggedIn && isProfessionnel" @click="office()">O365</b-button>
        <b-button size="md" class="button-menu btnHoverBleu" v-if="isLoggedIn && isAdmin" @click="administration()">Administration</b-button>
        <b-button size="md" class="button-menu btnHoverBleu" v-if="isLoggedIn" @click="logout()">[→</b-button>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import NavItemDropdownHover from './Dropdown/NavItemDropdownHover.vue'
export default {
  name: 'NavigationBar',
  data () {
    return {
      isLoggedIn: sessionStorage.getItem('token'),
      isAdmin: parseInt(sessionStorage.getItem('role')) === 1,
      isProfessionnel: parseInt(sessionStorage.getItem('role')) === 2
    }
  },
  components: {
    NavItemDropdownHover
  },
  computed: {
    lienForum () {
      return process.env.VUE_APP_FORUM_LIEN
    }
    // liens () {
    //   const liens = [
    //     {
    //       lien: '/',
    //       text: 'Répertoire'
    //     },
    //     {
    //       lien: this.lienForum + '/sante/',
    //       text: 'À propos',
    //       enfants: [
    //         {
    //           lien: this.lienForum + '/sante/',
    //           text: 'Qui sommes-nous?'
    //         },
    //         {
    //           lien: this.lienForum + '/tarifs/',
    //           text: 'Tarifs'
    //         },
    //         {
    //           lien: this.lienForum + '/nos-partenaires/',
    //           text: 'Nos partenaires'
    //         },
    //         {
    //           lien: this.lienForum + '/services/',
    //           text: 'Nos services'
    //         }
    //       ]
    //     }
    //   ]
    //   return liens
    // }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
        this.isLoggedIn = null
      })
    },
    profil () {
      history.pushState({}, document.title, window.location.href)
      this.$router.push({ name: 'Calendrier' })
    },
    administration () {
      history.pushState({}, document.title, window.location.href)
      this.$router.push({ name: 'pageAdmin' })
    },
    office () {
      location.replace('https://office.com')
    },
    showMenu () {
      this.click()
    },
    hideMenu () {
      this.find('.dropdown-menu').style.display = 'none'
    }
  }
}
</script>

<style scoped>
  .navbar-container {
    height: 100%;
    width: 100%;
  }
  .logo-header-container {
    height: 100%;
  }
  #logo-header
  {
    height: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .navContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .button-menu {
    margin-bottom: 0;
  }

  .menuTopLinks >>> .nav-link
  {
    color: black !important;
    opacity: 1;
  }
  .menuTopLinks
  {
    padding-right: 14px;
  }
  .nav-collapse {
    /* display: block; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  @media (min-width: 992px) {
    .dropdown:hover >>> .dropdown-menu:not(.submenu .dropdown-menu) {
      display: block;
    }
    .submenu >>> .dropdown-menu:not(.submenu .dropdown-menu){
      display:none;
    }
  }
  @media (max-width: 991px) {
    .navContainer {
      flex-direction: column;
    }
    .nav-collapse {
      /* display: block; */
      flex-direction: column;
    }
  }

@media screen and (max-width: 1250px) {
    #navbar-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    #logo-header
    {
      height: 60px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .button-menu:first {
      margin-right:0px;
    }
    .navbar-nav ml-auto
    {
      margin-right:auto !important;
    }
    .ml-auto, .mx-auto
    {
      margin-right:auto !important;
    }
    .menuTopLinks {
      font-size: 14px;
    }
    .menuTopLinks >>> .nav-link {
      font-size: 14px;
    }
    .menuTopLinks-item {
      font-size: 14px;
    }
    .menuTopLinks {
      padding-right: 0;
    }
    .button-menu {
      /* width: 60%;
      margin-left:20%; */
      font-size: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
</style>

<style>
  nav
  {
    font-size: 18px;
    color: black;
  }

    .navbar-light .navbar-nav .nav-link:hover
  {
    color: #29689c !important;
  }
  .navbar {
    z-index:300;
  }

  .btnHoverBleu:hover{
    background-color: #29689c !important;
  }

  .button-menu {
    margin-right:10px !important;
  }
.dropdown-menu{
  border:none !important;
  font-size: 16px;
  font-weight: 400;
}
.submenu .dropdown-menu{
  box-shadow: -2px 0px 5px 0px rgb(0 0 0 / 40%)
}
.dropdown-item:hover, .dropdown-item:focus,  .dropdown-menu .nav-link:hover, .dropdown-menu .nav-link:focus{
  background-color: #fff !important;
  color: #ffc90e !important;
}
.submenu .nav-link{
  padding: 8px 24px !important;
}
</style>
