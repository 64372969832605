<template>
  <AdminPageWrapper>
    <div class="container" v-if="isReady">
    <b-form v-on:submit.prevent="soumettreUtilisateur" class="form-container">
      <template v-for="field in fields">
        <b-form-group
          :label="field.label"
          :label-for="field.id"
          :key="field.id"
          class="inputGroup"
        >
          <b-form-select v-if="field.type === 'select'" :id="field.id" v-model="utilisateur[field.id]">
            <b-form-select-option v-for="option in field.options()" v-bind:key="option.id" v-bind:value="option.id">
              {{ option.name }}
            </b-form-select-option>
          </b-form-select>
          <b-form-file v-else-if="field.type === 'photo'" :id="field.id" :placeholder="field.label" v-model="photoFile" />
          <b-form-textarea v-else-if="field.type === 'textarea'" type="text" :id="field.id" :placeholder="field.label" v-model="utilisateur[field.id]" />
          <b-form-input v-else :required="field.required" :type="field.type" :id="field.id" :placeholder="field.label" v-model="utilisateur[field.id]" />
        </b-form-group>
      </template>
      <div class="btnContainer">
        <b-button class="buttonSoumettre" type="submit">Soumettre</b-button>
        <b-button class="buttonBack" @click="back()">Annuler</b-button>
      </div>
      <span style ="color:red" v-for="erreur in listeErreurs" v-bind:key="erreur">{{ erreur }}<br></span>
      </b-form>
      <b-modal id="modal-chargement" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered hide-footer title="Chargement">
        Chargement...
      </b-modal>
    </div>
  </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from '../components/AdminPageWrapper.vue'
export default {
  name: 'PageGestionCompte',
  components: {
    AdminPageWrapper
  },
  data () {
    return {
      utilisateur: {},
      photoFile: null,
      listeErreurs: [],
      retourServeur: '',
      isReady: false
    }
  },
  computed: {
    siNouveau () {
      return this.$route.params.id == null
    },
    isProfessionnel () {
      return this.utilisateur.role.name === 'Professionnel'
    },
    fields () {
      const fields = [
        {
          id: 'first_name',
          label: 'Prénom',
          type: 'text',
          required: true
        },
        {
          id: 'last_name',
          label: 'Nom de famille',
          type: 'text',
          required: true
        },
        {
          id: 'phone',
          label: 'Téléphone',
          type: 'tel',
          required: true
        },
        {
          id: 'email',
          label: 'Email',
          type: 'email',
          required: true
        },
        {
          id: 'address',
          label: 'Adresse',
          type: 'text',
          required: true
        },
        {
          id: 'zip_code',
          label: 'Code Postal',
          type: 'text',
          required: true
        },
        {
          id: 'city',
          label: 'Ville',
          type: 'text',
          required: true
        },
        {
          id: 'province',
          label: 'Province',
          type: 'text',
          required: true
        },
        {
          id: 'country',
          label: 'Pays',
          type: 'text',
          required: true
        },
        {
          id: 'description',
          label: 'Description',
          type: 'textarea'
        },
        {
          id: 'photo',
          label: 'Photo',
          type: 'photo'
        },
        {
          id: 'password',
          label: 'Mot de passe',
          type: 'password',
          required: this.siNouveau
        },
        {
          id: 'c_password',
          label: 'Confirmation de mot de passe',
          type: 'password',
          required: this.siNouveau
        }
      ]
      if (this.isProfessionnel) {
        const category = {
          id: 'category',
          label: 'Catégorie',
          type: 'select',
          options: this.getListeCategories
        }
        fields.splice(2, 0, category)
      }
      return fields
    }
  },
  methods: {
    getListeCategories () {
      return this.$store.getters.listeCategories
    },
    soumettreUtilisateur () {
      this.listeErreurs = []
      if (!this.utilisateur.first_name) {
        this.listeErreurs.push('Le prénom est requis')
      }
      if (!this.utilisateur.last_name) {
        this.listeErreurs.push('Le nom de famille est requis')
      }
      if (!this.utilisateur.phone) {
        this.listeErreurs.push('le numéro de téléphonne est requis')
      }
      if (!this.utilisateur.email) {
        this.listeErreurs.push('Le email est requis')
      }
      if (!this.utilisateur.city) {
        this.listeErreurs.push('La ville est requise')
      }
      if (!this.utilisateur.address) {
        this.listeErreurs.push('L\'adresse est requise')
      }
      if (!this.utilisateur.country) {
        this.listeErreurs.push('Le pays est requis')
      }
      if (!this.utilisateur.province) {
        this.listeErreurs.push('La province est requise')
      }
      if (!this.utilisateur.password) {
        if (this.siNouveau) {
          this.listeErreurs.push('Le mot de passe est requis')
        }
      }
      if (!this.utilisateur.c_password) {
        if (this.siNouveau) {
          this.listeErreurs.push('La confirmation de mot de passe est requise')
        }
      }
      if (this.utilisateur.password !== undefined && this.utilisateur.password !== this.utilisateur.c_password) {
        this.listeErreurs.push('La confirmation de mot de passe n\'est pas similaire au mot de passe')
      }
      if (!this.utilisateur.zip_code) {
        this.listeErreurs.push('Le code postal est requis')
      }
      // if (!this.utilisateur.price_per_hour) {
      //   this.listeErreurs.push('Le taux horaire est requis')
      // }
      if (this.listeErreurs.length === 0) {
        this.$bvModal.show('modal-chargement')
        this.retourServeur = this.$store.dispatch('registerOrUpdateUser', { user: this.utilisateur, photo: this.photoFile }).then((response) => {
          if (response.status === 200) {
            if (this.$route.params.id) {
              this.$store.dispatch('requestSpecificUserDetails', this.$route.params.id).then((user) => {
                this.utilisateur = user['data']
                this.$toast.success("L'utilisateur à été modifié", { duration: 5000 })
                this.$bvModal.hide('modal-chargement')
              })
            } else {
              history.pushState({}, document.title, window.location.href)
              this.$router.push({ name: 'pageAdmin' })
              this.$toast.success("L'utilisateur à été créé", { duration: 5000 })
              this.$bvModal.hide('modal-chargement')
            }
          }
        }).catch((err) => {
          if (err.response.data.email && err.response.data.email.includes('The email has already been taken.')) {
            this.$toast.error('Le courriel est déja utilisé', { duration: 5000 })
          }
          this.$bvModal.hide('modal-chargement')
        })
      }
    },
    back () {
      this.$router.push({ path: '/admin' })
    }
  },
  created () {
    if (!this.siNouveau) {
      this.$store.dispatch('requestSpecificUserDetails', this.$route.params.id).then((user) => {
        this.isReady = true
        this.utilisateur = user['data']
      })
    } else {
      this.utilisateur = {}
      this.isReady = true
    }
    this.$store.dispatch('fetchCategories')
  }
}
</script>

<style scoped>
img{
  width: 100px;
  height: 100px;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}
.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.inputGroup {
  width: 100%;
}
.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
</style>
