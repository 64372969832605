<template>
  <div id="passwordResetParentDiv">
    <form id="passwordResetContainer" v-on:submit.prevent="sendNewPassword">
      <img class="banniere" src ="@/assets/banniere.jpg">
      <h1>Réinitialisation du mot de passe</h1>
      <div>
        <b-form-input class="passwordResetField" @blur="$v.newPassword.$touch()" type="password" name="password" placeholder="Mot de passe" v-model="newPassword" />
        <div class="error" v-if="!$v.newPassword.required && $v.newPassword.$error">*Champ requis</div>
      </div>
      <div>
        <b-form-input class="passwordResetField" @blur="$v.c_newPassword.$touch()" type="password" name="c_password" placeholder="Confirmation du mot de passe" v-model="c_newPassword" />
        <div class="error" v-if="!$v.c_newPassword.required && $v.c_newPassword.$error">*Champ requis</div>
        <div class="error" v-if="!$v.c_newPassword.sameAsPassword && $v.c_newPassword.$error">Le mot de passe doit être identique</div>
      </div>
      <div>
        <b-button class="buttonSoumettre" type="submit">Modifier</b-button>
      </div>
    </form>
    <b-modal id="modal-chargement" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered hide-footer title="Connexion">
      Chargement...
    </b-modal>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
export default {
  name: 'NouveauMotDePasse',
  data () {
    return {
      errors: [],
      newPassword: '',
      c_newPassword: ''
    }
  },
  validations: {
    newPassword: {
      required
    },
    c_newPassword: {
      required,
      sameAsPassword: sameAs('newPassword')
    }
  },
  methods: {
    sendNewPassword () {
      this.errors = []
      if (this.$route.query.token) {
        const formData = new FormData()
        formData.append('password', this.newPassword)
        formData.append('token', this.$route.query.token)
        const object = {}
        formData.forEach((value, key) => { object[key] = value })
        const json = object
        this.$bvModal.show('modal-chargement')
        this.$store.dispatch('sendNewPassword', json).then(() => {
          this.$store.dispatch('logout').then(() => {
            this.$bvModal.hide('modal-chargement')
            history.pushState({}, document.title, window.location.href)
            this.$router.push({ name: 'Home' })
          })
        }).catch(() => {
          this.$bvModal.hide('modal-chargement')
          this.$toast.error('Il y a eu une erreur. Vérifiez si la demande est expirée.', { duration: 5000 })
        })
      } else {
        alert('il y a une erreur avec votre lien vers cette page, veuillez retourner sur cette page avec le lien dans votre email.')
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red
}
#passwordResetParentDiv
{
  display: flex;
  width: 100%;
  min-height: 55vh;
  align-items: center;
  flex-direction: column;
}
#passwordResetContainer
{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
#passwordResetContainer div {
  margin-bottom: 10px;
}
.passwordResetField
{
  width: 300px;
}
</style>
