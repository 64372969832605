import axios from 'axios'

const state = {
}

const getters = {
}

const actions = {
  setAuthUserNewsFeed ({ commit }, ifWantSub) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/setAuthUserNewsFeed',
        method: 'post',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: { ifWantSub: ifWantSub },
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        }
      }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
