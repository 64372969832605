import Vuex from 'vuex'
import Vue from 'vue'
import authentification from './modules/authentification.js'
import professionnels from './modules/professionnels.js'
import reservation from './modules/reservation.js'
import client from './modules/client.js'
import permis from './modules/permis.js'
import diplome from './modules/diplome.js'
import specialite from './modules/specialite.js'
import langue from './modules/langue.js'
import newsletter from './modules/newsletter.js'
import admin from './modules/admin.js'
import category from './modules/category.js'
import ordre from './modules/ordre.js'
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    authentification,
    professionnels,
    reservation,
    client,
    permis,
    diplome,
    specialite,
    langue,
    newsletter,
    admin,
    ordre,
    category
  }
})
