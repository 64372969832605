<template>
    <AdminPageWrapper>
      <div class="content">
        <div class="content-top">
          <div class="content-top-top">
            <span>Recherche</span><b-input class="user-searchbar" type="search" v-model="query" @input="updateTableData" />
          </div>
          <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="total"
          align="fill"
          class="user-pagination"
        ></b-pagination>
        </div>
        <div class="content-middle">
          <b-table ref="userTable" :fields="fields" :items="myProvider" :current-page="currentPage" :per-page="perPage">
            <template #cell(photo)="data">
              <img :src="imgFirstPartLink + data.item.photo" class="tblImg" />
            </template>
            <template #cell(multifactor_notification.email)="data">
              <b-form-checkbox :checked="data.value == 1" @change="multifactorEmailChange( data.item.id , $event)"/>
            </template>
            <template #cell(multifactor_notification.sms)="data">
              <b-form-checkbox :checked="data.value == 1" @change="multifactorSmsChange( data.item.id , $event)"/>
            </template>
            <template #cell(permanentBlock)="data">
              <b-form-checkbox :checked="data.value == 1" @change="permanentBlockChange( data.item.id , $event)"/>
            </template>
            <template #cell(editUser)="data">
              <router-link :to="{ name: 'pageGestionCompte', params: { id: data.item.id } }">Modifier l'utilisateur</router-link>
            </template>
          </b-table>
        </div>
      </div>
    </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from '@/components/AdminPageWrapper.vue'
export default {
  name: 'ListeUtilisateur',
  computed: {
    imgFirstPartLink () {
      return process.env.VUE_APP_BACKEND_STORAGE_URL
    }
  },
  components: {
    AdminPageWrapper
  },
  methods: {
    multifactorEmailChange (userId, email) {
      this.$store.dispatch('save2FaForUser', { userId: userId, email: email })
    },
    multifactorSmsChange (userId, sms) {
      this.$store.dispatch('save2FaForUser', { userId: userId, sms: sms })
    },
    permanentBlockChange (userId, blocked) {
      this.$store.dispatch('saveBlockedForUser', { userId: userId, blocked: blocked })
    },
    updateTableData () {
      this.$refs.userTable.refresh()
    },
    myProvider (ctx, callback) {
      const query = this.query
      const noPage = ctx.currentPage - 1
      const itemsPerPage = ctx.perPage
      this.$store.dispatch('searchUserListByName', { query, noPage, itemsPerPage }).then((userList) => {
        callback(userList.lst)
        this.total = userList.total
      }).catch(() => {
        callback()
      })
      return null
    }
  },
  data () {
    return {
      test: true,
      query: '',
      currentPage: 1,
      perPage: 5,
      total: 0,
      fields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'photo',
          sortable: true
        },
        {
          key: 'fullname',
          label: 'Nom',
          sortable: true
        },
        {
          key: 'email',
          label: 'Courriel',
          sortable: true
        },
        {
          key: 'role.name',
          label: 'Rôle',
          sortable: true
        },
        {
          key: 'multifactor_notification.email',
          label: '2FA Email'
        },
        {
          key: 'multifactor_notification.sms',
          label: '2FA Téléphone'
        },
        {
          key: 'permanentBlock',
          label: 'Bloqué'
        },
        {
          key: 'editUser',
          label: 'Modifier'
        }
      ]
    }
  }
}
</script>

<style scoped>
/* >>> button {
  background-color: #29689c !important;
} */
.tblImg {
  width: 50px;
  height: 50px;
}
.content-middle {
  display: flex;
  flex-direction: column;
  width: 99vw;
  align-items: flex-start;
  background-color: white;
}
.content-top-top{
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.content-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.user-searchbar {
  min-width: 200px;
  width: 50%;
  max-width: 100%;
  margin-left: 10px;
}
.user-pagination {
  min-width: 200px;
  width: 40%;
  max-width: 500px;
  margin-left: 10px;
}
</style>
